
import { defineComponent, ref ,toRaw, nextTick, onUnmounted} from "vue";
import PublicBanner from "./components/banner.vue";
import PublicHead from '@com/PublicHead.vue'
import DealFoot from '@com/DealFoot.vue' 
import RouterNav from "../../components/steel/router-nav.vue";
import StewardRoutes from "@/router/modules/StewardRoutes";

export default defineComponent({
  name: "StewardInventory",
  components: {
    PublicHead,
    PublicBanner,
    DealFoot,RouterNav,
  },
  setup() {
    let isRouterView = ref(true)
    let currentRoutes = StewardRoutes.filter(item=>item.title == "钢贸管家")
    let routeList = currentRoutes.pop().children
    const routerLink =async() =>{
      isRouterView.value = false
      await nextTick()
      isRouterView.value = true
    }
    onUnmounted(()=>{ localStorage.removeItem('storageRoutes')  })
    return {
      routeList,
      isRouterView,
      routerLink,
    };
  },
});
