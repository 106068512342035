import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-767b046c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["onClick"]

import { inject,provide,ref,toRaw } from "vue"
import { useRouter } from "vue-router"

export default {
  props: {routerList:Array},
  emits: ['routerLink'],
  setup(__props, { emit: emits }) {

const props = __props

const router = useRouter()

let storageRoutes =  localStorage.getItem('storageRoutes') || ''
let routesList = storageRoutes?JSON.parse(storageRoutes):toRaw(props).routerList
let newRouterList = ref([...routesList]) 
function getRouter (current_path){
  for (let i = 0; i < routesList.length; i++) {
    if (routesList[i].path==current_path) {
      let children = routesList[i]
      routesList.splice(i,1)
      routesList = [children,...routesList];
      newRouterList.value.splice(0,routesList.length)
      newRouterList.value.push(...routesList)
      let storageRoutes = JSON.stringify(newRouterList.value)
      localStorage.setItem('storageRoutes',storageRoutes)
      router.push(current_path)
      return
    }
  }
}

provide("reload",emits('routerLink'))

return (_ctx, _cache) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(newRouterList), (item, i) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "nav_item",
      key: i,
      style: _normalizeStyle({backgroundImage:`url(${item.meta.background})`})
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(item.title), 1),
      _createElementVNode("p", {
        class: "goto pointer",
        onClick: $event => (getRouter(item.path))
      }, "去查看", 8, _hoisted_2)
    ], 4))
  }), 128))
}
}

}